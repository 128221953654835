/*  SECTIONS  */
html { font-size: 22px; 
  background-color: #fafafa;}
/* body { padding: 1rem; } */

.cards {
    display:grid;
    /* grid-template-columns:repeat(12, 1fr); */
    grid-auto-rows:140px;
    gap:20px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }

  .cardsHidden {
    /* display:grid; */
    grid-template-columns:repeat(12, 1fr);
    grid-auto-rows:140px;
    gap:20px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }

  @media (max-width: 600px) {
    .cardsHidden { display:none}

    .dashboardText{
      display:none
    }
    
    body { padding: 0rem; } 
  }

  @media (max-width: 900px) {
    .cards { grid-template-columns: "repeat(4, 1fr)"; }
  }