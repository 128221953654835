/* :root {
    --primary-color:#ff0000;
} */

.loginPage{
    height:100%;
    background-color: #fafafa;
    overflow-x: hidden;
}

.loginBody{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.loginBody > *{
    align-self: center;
}

.loginBannerDiv{
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: fit-content;
    position: relative;
    /* overflow: unset; */
    /* overflow-x: hidden; */
}

.loginBanner{
    transform: scaleX(-1);
}

.loginTransparentLogo{
    position: absolute;
    left:0%;
    bottom:0%;
    height: 96px;
    min-height: 50px;
    margin-left: 2rem;
    margin-bottom: 2rem;
}

.loginTransparentLogoMobile{
    position: absolute;
    left:50%;
    bottom:0%;
    height: 96px;
    min-height: 50px;
    transform: translate(-50%, 100%);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    /* margin-left: 2rem; */
    /* margin-bottom: 2rem; */
}

.loginHastag {
    position: absolute;
    left:0%;
    bottom:0%;
    color:rgb(242,206,135);
    font-weight: 600;
    font-size: 18px;
    margin-left: 2rem;
    margin-bottom: 0.5rem;
}

.loginHastagMobile {
    position: absolute;
    left:0%;
    bottom:0%;
    color:rgb(242,206,135);
    font-weight: 600;
    font-size: 18px;
    margin-left: 2rem;
    margin-bottom: 0rem;
}

@media only screen and (max-width: 1200px) {
    .loginBannerDiv{
        height: 21.75vw;
    }
    
    .loginTransparentLogo{
        height: 8vw;
        
    }
}

.loginText{
    color: rgba(0,0,0,.5);
    text-align: center;
    font-size: 26px!important;
    margin-top: 3rem!important;
}

.loginTextMobile{
    color: rgba(0,0,0,.5);
    text-align: center;
    font-size: 16px!important;
    margin-top: 5.5rem!important;
}

.loginText2{    
    color: #B09363;
    font-size: 22px!important;
    font-weight: bold;
    /* margin-top: 3rem!important; */
}

.loginBox{
    width: 468px;
    margin-top: 3rem!important;
    /* position: absolute;
    top: 50%;
    transform: translate(0, -50%); */
}

.loginBoxMobile{
    width: 90%;
    max-width: 468px;
    margin-top: 2rem!important;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
}

.loginButton{
    background-color: #AB9469!important;
    color: white!important;
    width: 100%;
    font-size: 19px!important;
    height: 56.7px;
    text-transform: none!important;
    font-weight: 600!important;
    border-radius: 8px!important;
}

.loginButtonMobile{
    background-color: #AB9469!important;
    color: white!important;
    width: 100%;
    font-size: 19px!important;
    height: 52.7px;
    text-transform: none!important;
    font-weight: 600!important;
    border-radius: 8px!important;
}

.linksDiv {
    margin-top: 5rem;
}

.linksDivMobile {
    position: absolute;
    bottom: 1rem;
}

.linkButtonsDiv{
    display: flex;
    flex-direction: row;
}

.linkButtonsDiv > * {
    color: #B09363!important
}

.MuiFormControl-root{
    background-color: white ;
}

.MuiInputBase-root{
    color: rgb(30, 30, 30) !important;
}

.MuiInputLabel-root{
    color: rgb(204,204,204)!important
}

.MuiInputLabel-root.Mui-focused{
    color: rgb(22, 22, 22)!important
}

/* @media only screen and (max-width: 1040px) {
    .loginBox{
        left: 50%;
        margin-right:0px;
        overflow-x:hidden;
        max-width: 100vw;
        width:70vw;

    }
} */

.MuiInputBase-root .MuiSvgIcon-root{
    color: rgb(204,204,204)
}

.passwordField .Mui-focused .MuiSvgIcon-root{
    color: rgb(30, 30, 30)!important
}